<template>
  <div class="CyberSecurity" >
    <section id="cyber-main-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Cybersécurité
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 ">
            <p class="section-slogan">
              Des sytèmes et une infrastructure sécurisés pour une activité pérenne.
            </p>
            <p class="section-explanation">
              Les attaques cyber ont augmenté de plus de 25% en une année : ransomware, malware, rootkits, spyware, ...
              profitent des failles de vos systèmes et mettent en péril votre activité.
              A destination des TPE/PME et indépendants, nous auditons et configurons l'ensemble de votre système
              (OS, navigateurs, logiciels, réseaux) pour vous garantir une sécurité et confidentialité maximales.<br>
              Nous dressons des cyberportraits par récupération algorithmique d'informations,
              menons des cyberinvestigations et testons les failles de votre activité face à des attaques de
              social engineering*. Nous testons vos applications web, vos réseaux ainsi que la résistance de
              vos mots de passe*.<br>
              <em><small>* dans un cadre autorisé réglementairement</small></em>
            </p>
          </div>
        </div>
      </div>
    </section>


    <section id="cyber-proposition-section">
      <div class="container">
        <div class="row section-title-margin">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title">
              Nos métiers
            </h3>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-white">
          <div class="col-12">
            <div class="alt-tabs-icon">
              <b-icon-bounding-box-circles></b-icon-bounding-box-circles>
            </div>
            Sécurité des réseaux et des systèmes
          </div>
        </div>
        <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
          <div class="col-12 text-center">
            <p>
              Configuration de vos systèmes (OS, navigateurs, logiciels) pour vous
              garantir une sécurité et une confidentialité maximale.
            </p>
            <p>
              Installation de system-live, de virtual-machines sur votre système
              pour garantir votre anonymat sur le web.
            </p>
            <p>
              Penetration testing de votre réseaux, configuration de
              vos pare-feux et architecture de votre réseaux (isolation network).
            </p>
          </div>
        </div>


        <div class="row basic-alt-tabs text-explanation-on-white" style="margin-top: 50px;">
          <div class="col-12">
            <div class="alt-tabs-icon">
              <b-icon-shield-lock></b-icon-shield-lock>
            </div>
            Sécurité des applications web et serveurs
          </div>
        </div>
        <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
          <div class="col-12 text-center">
            <p>
              Intrusion dans les systèmes web et réseaux*.
            </p>
            <p>
              Tests de résistance de mots de passe.
            </p>
            <p>
              Accompagnement à la protection des sites web et serveurs.
            </p>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-white" style="margin-top: 50px;">
          <div class="col-12">
            <div class="alt-tabs-icon">
              <b-icon-eye></b-icon-eye>
            </div>
            Cyberinvestigations
          </div>
        </div>
        <div class="row section-explanation text-explanation-on-white" style="margin-top: 20px;">
          <div class="col-12 text-center">
            <p>
              Dressage de cyber portraits (récupération algorithmique de toutes les informations cyber existantes).
            </p>
            <p>
              Crawling du <em>clear, deep et dark web</em>.
            </p>
            <p>
              Analyse des réseaux sociaux et <em>social engineering*</em>.
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <p align="right" class="mt-4">
          <small>
            <em>
              *dans un cadre réglementaire autorisé
            </em>
          </small>
        </p>
      </div>

    </section>


    <section id="cyber-launch-project-section">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="font-alt section-title section-title-margin">
              Lancez votre projet
            </h3>
          </div>
        </div>

        <div class="row basic-alt-tabs text-explanation-on-white">
          <div class="col-12 text-center">
            <b-button to='/contact' class="p-3" variant="outline-light" size='lg'>
              Nous contacter
            </b-button>
          </div>
        </div>
        <hr style="background-color:#D8D8D8; margin-top: 70px;" width="30%">
      </div>
    </section>
    <footerComponent />
  </div>
</template>

<script>
const footerComponent = () => import('@/components/footerComponent')

export default {
  name: 'CyberSecurity',
  components: {
    footerComponent,
  },
}
</script>

<style>
#cyber-main-section {
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: black;
  color: white;
}

#cyber-proposition-section {
  padding-bottom: 80px;
}


#cyber-launch-project-section {
  padding-bottom: 10px;
  background-color: black;
  color: white;
}



@media (min-width: 480px) {
  #cyber-launch-project-section {
    padding-bottom: 40px;
  }

}


</style>
